@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  background-color: #f8f9fa !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
